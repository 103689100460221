import { graphql, navigate } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React, { useEffect } from "react";
import { useState } from "react";
import { Oval } from "react-loader-spinner";
import YellowButton from "../components/Buttons/YellowButton";
import Seo from "../components/SEO";
import { useMenuState } from "../context";
import Layout from "../hoc/Layout";
import { getRedirectLanguage } from "../utils/getRedirectLanguages";
import { testLangUrlPathname } from "../utils/testLangUrlPathname";

const SubscriptionEndpointTemplate = ({
  pageContext,
  data: {
    subscription: {
      heroImage,
      functions = [],
      id,
      metadata,
      pageName,
      redirectBadRequests = true,
      seoMetaTags,
    },
    lightBg,
    logo,
  },
  location,
}) => {
  const { setMenuOpen } = useMenuState();

  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(true);

  const heroBg = getImage(heroImage);
  const pageBg = getImage(lightBg.childImageSharp.gatsbyImageData);
  const _logo = getImage(logo.childImageSharp.gatsbyImageData);

  const heroBackground = convertToBgImage(heroBg);
  const pageBackground = convertToBgImage(pageBg);

  const subActionObj = functions.find(({ actionTag }) => actionTag === "S");
  const unSubActionObj = functions.find(({ actionTag }) => actionTag === "U");

  const getParameterByName = (name) => {
    /* eslint-ignore-no-useless-escape */
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const action = getParameterByName("act");
  const endpointId = getParameterByName("id");
  const endpointEmail = getParameterByName("email");

  const timestamp = new Date().toISOString();

  const generateKeyPair = (optionName) => {
    switch (optionName) {
      case "source":
        return {
          source: location.pathname,
        };
      case "optTimestamp":
        return {
          optTimestamp: timestamp.toString(),
        };
      case "locale":
        return {
          locale: pageContext.locale,
        };
      case "id":
        return {
          endpointId: endpointId,
        };
      case "email":
        return {
          endpointEmail: endpointEmail,
        };
      default:
        console.log("There is not an option available for this.");
        break;
    }
  };

  useEffect(() => {
    setMenuOpen(false);
    return () => setMenuOpen(false);
  }, [location, setMenuOpen]);

  useEffect(() => {
    if (action === "S") {
      let payload = {
        optOutAll: false,
        action: "S",
      };

      JSON.parse(subActionObj.expectedParameters).map((option) => {
        const item = generateKeyPair(option);
        return (payload = { ...payload, ...item });
      });

      JSON.parse(subActionObj.payloadOptions).map((option) => {
        const item = generateKeyPair(option);
        return (payload = { ...payload, ...item });
      });

      subscribe(JSON.stringify(payload));
    }
    if (action === "U") {
      let payload = {
        optOutAll: true,
        action: "U",
      };
      JSON.parse(unSubActionObj.expectedParameters).map((option) => {
        const item = generateKeyPair(option);
        return (payload = { ...payload, ...item });
      });

      JSON.parse(unSubActionObj.payloadOptions).map((option) => {
        const item = generateKeyPair(option);
        return (payload = { ...payload, ...item });
      });
      unSubscribe(JSON.stringify(payload));
    }
  }, []);

  const subscribe = async (data) => {
    await fetch(subActionObj.lambdaUrl, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setLoading(false);
        setMsg(subActionObj.successMessage);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(subActionObj.errorMessage);
      });
  };

  const unSubscribe = async (data) => {

    await fetch(unSubActionObj.lambdaUrl, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setLoading(false);
        setMsg(unSubActionObj.successMessage);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(unSubActionObj.errorMessage);
      });
  };

  // Redirect Off Page.
  // useEffect(() => {
  //     if (!action && redirectBadRequests) navigate('/')
  // }, [action, redirectBadRequests])

  return (
    <Layout locale={pageContext.locale}>
      <Seo meta={seoMetaTags?.tags} title={metadata?.title} />
      <BackgroundImage
        tag="section"
        {...heroBackground}
        className="stacking-context-9"
        style={{
          backgroundPosition: "center",
        }}
      >
        <div className="min-h-[500px] md:h-[625px] lg:min-h-[700px] flex items-center justify-center relative">
          <GatsbyImage
            image={_logo}
            alt={"The Cycle: Frontier Logo"}
            className="max-w-[450px] w-10/12 mx-auto"
          />
          <div className="bg-darks5 bg-opacity-50 absolute w-full h-full z-[-10]" />
        </div>
      </BackgroundImage>
      <BackgroundImage
        tag="section"
        {...pageBackground}
        className="stacking-context-8"
        style={{
          backgroundRepeat: "repeat",
          backgroundSize: "800px",
        }}
      >
        <div className="min-h-[40vh] py-20">
          <div className="py-12 lg:py-16 lg:grid lg:grid-cols-18 text-darks1">
            <div className="font-display my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-16 max-w-screen-lg mx-auto w-11/12 flex items-center justify-center flex-col text-center">
              {loading ? (
                <>
                  <h1 className="uppercase text-5xl mb-8">
                    Your request is being handled...
                  </h1>
                  <Oval
                    color="#79c6f2"
                    secondaryColor="#121c25"
                    height={50}
                    width={50}
                  />
                </>
              ) : (
                <>
                  <h1 className="uppercase text-5xl mb-8">{msg}</h1>
                  <YellowButton link={"/"} text="Home" />
                </>
              )}
            </div>
          </div>
        </div>
      </BackgroundImage>
    </Layout>
  );
};

export default SubscriptionEndpointTemplate;

export const query = graphql`
  query SubscriptionEndpointQuery($id: String, $locale: String) {
    subscription: datoCmsSubscriptionEndpointPage(
      id: { eq: $id }
      locale: { eq: $locale }
    ) {
      id
      locale
      pageName
      heroImage {
        gatsbyImageData(placeholder: BLURRED)
        alt
      }
      metadata {
        description
        title
      }
      seoMetaTags {
        tags
      }
      redirectBadRequests
      functions {
        ... on DatoCmsLambdaFunctionGenerator {
          __typename
          lambdaUrl
          actionTag
          expectedParameters
          payloadOptions
          successMessage
          errorMessage
        }
      }
    }
    logo: file(
      relativePath: { eq: "images/the-cycle-logo-final-bw-horizon2_white.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    lightBg: file(
      relativePath: { eq: "images/textures/texture-img-light.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
